<template>
  <nav>
    <v-app-bar app flat>
      <v-toolbar-title class="text-uppercae">
        <span class="font-weight-bold red--text">Weather</span>
        <span class="font-weight-light red--text">Station </span>
        <span class="font-weight-bold red--text">V2.0</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn text color="primary" @click="logoutUser" v-if="isLoggedIn"
        >Logout</v-btn
      >
    </v-app-bar>
  </nav>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    logoutUser() {
      this.$store.dispatch("logoutUser");
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.token != "";
    },
  },
};
</script>