import Vue from 'vue'
import Vue2Filters from 'vue2-filters'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import '@/helpers/filters'

Vue.use(Vue2Filters)

// import axios from 'axios'

// Vue.config.productionTip = false

// Vue.use(axios)

// axios.interceptors.request.use(req => {
//   const token = store.state.token;
//   if (token != '') {
//     req.headers.common['Authorization'] = "Bearer" + token;
//   } else {
//     delete req.headers.common['Authorization'];
//   }
//   return req;
// });

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
