<template>
  <div><UserAuth /></div>
</template>

<script>
import UserAuth from "@/components/UserAuth";

export default {
  name: "Login",
  components: {
    UserAuth,
  },
};
</script>