<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card class="ma-10 pa-10">
        <v-text-field
          :rules="emailRules"
          v-model.trim="email"
          label="E-Mail"
          required
          @click="validate"
        ></v-text-field>
        <v-text-field
          v-model="password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          :rules="passwordRules"
          v-model.trim="password"
          label="Password"
          required
          @click="validate"
          @click:append="show = !show"
        ></v-text-field>
        <v-btn
          class="mr-4"
          color="success"
          :disabled="!valid"
          rounded
          @click="submitForm"
          >Login</v-btn
        >
      </v-card>
    </v-form>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    show: false,
    email: "",
    password: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [(v) => !!v || "Password is required"],
  }),
  methods: {
    submitForm() {
      console.log("SubmitForm called");
      this.validate();
      const authData = { email: this.email, password: this.password };
      this.$store.dispatch("loginUser", authData);
    },
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>