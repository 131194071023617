import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@/router'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: '',
    expiresIn: '',
    datetime: '',
    temperature: 0.0,
    humidity: 0.0,
    lightIntensity: 0.0,
    measurementRangeData: null,
    measurementStatisticsData: null
  },
  mutations: {
    setAuthToken(state, authToken) {
      console.log("AuthToken: ", authToken);
      state.token = authToken.token;
      state.expiresIn = authToken.expiresIn;
    },
    resetAuthToken(state) {
      state.token = '';
      state.expiresIn = '';
    },
    setDateTime(state, datetime) {
      state.datetime = datetime;
    },
    setTemperature(state, temperature) {
      state.temperature = temperature;
    },
    setHumidity(state, humidity) {
      state.humidity = humidity;
    },
    setLightIntensity(state, lightIntensity) {
      state.lightIntensity = lightIntensity;
    },
    setMeasurementRangeData(state, measurementRangeData) {
      state.measurementRangeData = measurementRangeData;
    },
    setMeasurementStatisticsData(state, statisticsData) {
      state.measurementStatisticsData = statisticsData;
    }
  },
  actions: {
    loginUser(context, authData) {
      console.log("LoginUser Called", authData);
      const backendURL = process.env.VUE_APP_BACKEND_URL;
      axios.post(backendURL + '/user/login', authData).then(response => {
        context.commit('setAuthToken', response.data);
        router.push('/');
      }).catch(error => {
        console.log(error);
        context.commit('resetAuthToken');
      });
    },
    logoutUser(context) {
      console.log("LogoutUser Called");
      context.commit('resetAuthToken');
      router.push("/login");
    },
    getMeasurements(context) {
      console.log("GetMeasurements Called");
      const station = process.env.VUE_APP_WEATHER_STATION;
      const backendURL = process.env.VUE_APP_BACKEND_URL;
      axios.get(backendURL + "/measurements/" + station + "/latest").then(response => {
        console.log("GetMeasurements Response: ", response);
        context.commit('setDateTime', response.data.measurement.datetime);
        context.commit('setTemperature', response.data.measurement.temperature);
        context.commit('setHumidity', response.data.measurement.humidity);
        context.commit('setLightIntensity', response.data.measurement.lightintensity);
      }).catch(error => {
        console.log(error);
        context.commit('resetAuthToken');
      });
    },
    getMeasurementRange(context, queryData) {
      console.log("GetMeasurementRange Called", queryData);
      const station = process.env.VUE_APP_WEATHER_STATION;
      const backendURL = process.env.VUE_APP_BACKEND_URL;
      axios.post(backendURL + "/measurements/" + station + "/range", queryData).then(response => {
        console.log("GetMeasurementRange Response: ", response);
        context.commit('setMeasurementRangeData', response.data.measurements);
      }).catch(error => {
        console.log(error);
        context.commit('resetAuthToken');
      });
    },
    getMeasurementStatisticsData(context) {
      console.log("GetmeasurementStatisticsData Called");
      const station = process.env.VUE_APP_WEATHER_STATION;
      const backendURL = process.env.VUE_APP_BACKEND_URL;
      axios.get(backendURL + "/measurements/" + station + "/averages").then(response => {
        console.log("GetmeasurementStatisticsData Response: ", response);
        context.commit('setMeasurementStatisticsData', response.data.measurementAverages);
      }).catch(error => {
        console.log(error);
        context.commit('resetAuthToken');
      });
    }
  },
  getters: {
    isLoggedIn(state) {
      return state.token != '';
    },
    temperature(state) {
      return state.temperature;
    },
    humidity(state) {
      return state.humidity;
    },
    lightIntensity(state) {
      return state.lightIntensity;
    },
    measurementRangeData(state) {
      return state.measurementRangeData;
    },
    measurementStatisticsData(state) {
      return state.measurementStatisticsData;
    }
  },
  modules: {
  }
})

export default store;