<template>
  <v-app style="background: #f5f5f5">
    <v-main class="mx-4 mb-4">
      <div><Navbar /></div>
      <v-tabs v-if="isLoggedIn" centered background-color="transparent">
        <v-tab router to="/">Dashboard</v-tab>
        <v-tab router to="/hist">History</v-tab>
        <v-tab router to="/stats">Staticstics</v-tab>
      </v-tabs>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
import UserAuth from "@/components/UserAuth";
import store from "@/store";

export default {
  name: "App",
  components: {
    Navbar,
    UserAuth,
  },
  data: () => ({
    //
  }),
  computed: {
    isLoggedIn() {
      return store.getters.isLoggedIn;
    },
  },
};
</script>