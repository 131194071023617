<template>
  <div class="pt-16">
    <div class="text-lg-h6 text-uppercase text-center">Temperature</div>
    <v-container class="my-5">
      <div class="hello" ref="chartdiv"></div>
    </v-container>
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

import Vue from 'vue';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
Vue.use(VueFilterDateFormat);

export default {
  name: 'Statistics',
  components: {},
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    measurementStatisticsData() {
      return this.$store.getters.measurementStatisticsData;
    },
  },
  watch: {
    measurementStatisticsData(statisticsData) {
      console.log('Watch MeasurementStatisticsData Called', statisticsData);
      let data = [];
      for (let s in statisticsData) {
        let category = statisticsData[s]._id;
        const avg_temperature = statisticsData[s].avg_temperature;
        const min_temperature = statisticsData[s].min_temperature;
        const max_temperature = statisticsData[s].max_temperature;

        const t = category.split(' / ');
        let date = new Date(t[0], t[1] - 1, 1);
        let longMonth = date.toLocaleString('en-us', {
          month: 'long',
        }); /* June */
        category = longMonth + ' ' + t[0];

        data.push({
          category: category,
          first: min_temperature,
          second: avg_temperature,
          third: max_temperature,
        });
      }
      this.chart.data = data;
    },
  },
  methods: {
    pollMeasurements() {
      this.$store.dispatch('getMeasurementStatisticsData');
    },
  },
  mounted() {
    let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
    chart.colors.step = 2;

    chart.legend = new am4charts.Legend();
    chart.legend.position = 'bottom';
    chart.legend.paddingBottom = 20;
    chart.legend.labels.template.maxWidth = 95;

    let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = 'category';
    xAxis.renderer.cellStartLocation = 0.1;
    xAxis.renderer.cellEndLocation = 0.9;
    xAxis.renderer.grid.template.location = 0;

    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // yAxis.min = 0;

    function createSeries(value, name, hidden) {
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = value;
      series.dataFields.categoryX = 'category';
      series.name = name;
      series.hidden = hidden;

      series.columns.template.tooltipText =
        //"[bold ]{category}[/]\n {name}: {valueY.formatNumber('0.0')} °C";
        "[]{category}[/]\n  [bold font-size: 20px]{valueY.formatNumber('0.0')} °C[/]";

      series.events.on('hidden', arrangeColumns);
      series.events.on('shown', arrangeColumns);

      // var bullet = series.bullets.push(new am4charts.LabelBullet());
      // bullet.interactionsEnabled = false;
      // bullet.dy = 30;
      // bullet.label.text = "{valueY}";
      // bullet.label.fill = am4core.color("#ffffff");

      return series;
    }

    createSeries('first', 'Minimum', true);
    createSeries('second', 'Average', false);
    createSeries('third', 'Maximum', true);

    function arrangeColumns() {
      var series = chart.series.getIndex(0);

      var w =
        1 -
        xAxis.renderer.cellStartLocation -
        (1 - xAxis.renderer.cellEndLocation);
      if (series.dataItems.length > 1) {
        var x0 = xAxis.getX(series.dataItems.getIndex(0), 'categoryX');
        var x1 = xAxis.getX(series.dataItems.getIndex(1), 'categoryX');
        var delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          var middle = chart.series.length / 2;

          var newIndex = 0;
          chart.series.each(function(series) {
            if (!series.isHidden && !series.isHiding) {
              series.dummyData = newIndex;
              newIndex++;
            } else {
              series.dummyData = chart.series.indexOf(series);
            }
          });
          var visibleCount = newIndex;
          var newMiddle = visibleCount / 2;

          chart.series.each(function(series) {
            var trueIndex = chart.series.indexOf(series);
            var newIndex = series.dummyData;

            var dx = (newIndex - trueIndex + middle - newMiddle) * delta;

            series.animate(
              { property: 'dx', to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
            series.bulletsContainer.animate(
              { property: 'dx', to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
          });
        }
      }
    }

    this.chart = chart;

    this.pollMeasurements();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  width: 100%;
  height: 600px;
}
</style>
