import moment from 'moment';

function getTodayStart() {
  const now = moment();
  const startToday = now.startOf('day');
  const result = startToday.toDate();
  // console.log('Time TodayStart: ', result);
  return result;
}

function getTomorrowStart() {
  const now = moment();
  const startToday = now.startOf('day');
  const startTomorrow = startToday.add(1, 'days');
  const result = startTomorrow.toDate();
  // console.log('Time TomorrowStart: ', result);
  return result;
}

function getYesterdayStart() {
  const now = moment();
  const startToday = now.startOf('day');
  const startYesterday = startToday.subtract(1, 'days');
  const result = startYesterday.toDate();
  // console.log('Time YesterdayStart: ', result);
  return result;
}

function getWeekStart() {
  const now = moment();
  const startWeek = now.startOf('isoWeek'); // start of week is Monday
  const result = startWeek.toDate();
  // console.log("Time WeekStart: ", result);
  return result;
}

function getMonthStart() {
  const now = moment();
  now.startOf('month');
  const startMonth = now.toDate();
  // console.log("Time LastMonthStart", startMonth);
  return startMonth;
}

function getYearStart() {
  const now = moment();
  now.startOf('year');
  const startYear = now.toDate();
  // console.log("Time YearStart", startYear);
  return startYear;
}

function getWeekEnd() {
  const weekStart = getWeekStart();
  const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  const weekEnd = new Date(weekStart.getTime() + sevenDaysInMilliseconds - 1);
  /* console.log("Time WeekEnd", weekEnd) */
  return weekEnd;
}

function getLastWeekStart() {
  const weekStart = getWeekStart();
  const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  const lastWeekStart = new Date(weekStart.getTime() - sevenDaysInMilliseconds);
  // console.log("Time LastWeekStart: ", d);
  return lastWeekStart;
}

function getLastMonthStart() {
  const now = moment();
  const lastMonth = now.subtract(1, 'months');
  lastMonth.startOf('month');
  const result = lastMonth.toDate();
  // console.log("Time LastMonthStart", result);
  return result;
}

function getLastYearStart() {
  const now = moment();
  now.startOf('year');
  const lastYear = now.subtract(1, 'years');
  const result = lastYear.toDate();
  // console.log("Time LastYearStart", result);
  return result;
}

function getNumDaysAgo(numDays) {
  const now = moment();
  const startToday = now.startOf('day');
  const numDaysAgo = startToday.subtract(numDays, 'days');
  const result = numDaysAgo.toDate();
  // console.log('Time ${numDays}_DaysAgo: ', result);
  return result;
}

export {
  getTodayStart,
  getTomorrowStart,
  getYesterdayStart,
  getWeekStart,
  getMonthStart,
  getYearStart,
  getWeekEnd,
  getLastWeekStart,
  getLastMonthStart,
  getLastYearStart,
  getNumDaysAgo,
};
