<template>
  <div>
    <div class="text-center">
      <v-container class="pt-16">
        <v-btn-toggle
          class="text-center"
          v-model="toggle_one"
          tile
          group
          mandatory
        >
          <v-btn class="rounded-xl" text small @click="setRangeToday"
            >Today</v-btn
          >
          <v-btn class="rounded-xl" text small @click="setRangeWeek"
            >This week</v-btn
          >
          <v-btn class="rounded-xl" text small @click="setRangeMonth"
            >This month</v-btn
          >
          <v-btn class="rounded-xl" text small @click="setRangeYear"
            >This year</v-btn
          >
          <v-btn class="rounded-xl" text small @click="setRangeLastWeek"
            >Last week</v-btn
          >
          <v-btn class="rounded-xl" text small @click="setRangeLastMonth"
            >Last month</v-btn
          >
          <v-btn class="rounded-xl" text small @click="setRangeLastYear"
            >Last year</v-btn
          >
          <v-btn class="rounded-xl" text small @click="setRangeYesterday"
            >Yesterday</v-btn
          >
          <v-btn class="rounded-xl" text small @click="setRangeLast7Days"
            >Last 7 days</v-btn
          >
          <v-btn class="rounded-xl" text small @click="setRangeLast30Days"
            >Last 30 days</v-btn
          >
          <v-btn class="rounded-xl" text small @click="setRangeLast90Days"
            >Last 90 days</v-btn
          >
        </v-btn-toggle>
      </v-container>
    </div>
    <v-container class="my-5">
      <div class="hello" ref="chartdiv"></div>
    </v-container>
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import * as timeservice from '@/helpers/time';

am4core.useTheme(am4themes_animated);

let today = timeservice.getTodayStart();
let tomorrow = timeservice.getTomorrowStart();
let startWeek = timeservice.getWeekStart();
let weekEndDay = timeservice.getWeekEnd();
let startMonth = timeservice.getMonthStart();
let startYear = timeservice.getYearStart();
let yesterday = timeservice.getYesterdayStart();
let lastWeekStart = timeservice.getLastWeekStart();
let startLastMonth = timeservice.getLastMonthStart();
let startLastYear = timeservice.getLastYearStart();
let sevenDaysAgo = timeservice.getNumDaysAgo(7);
let thirtyDaysAgo = timeservice.getNumDaysAgo(30);
let ninetyDaysAgo = timeservice.getNumDaysAgo(90);

export default {
  name: 'History',
  components: {},
  data() {
    return {
      chart: null,
      sDate: today,
      eDate: tomorrow,
      toggle_one: 0,
    };
  },
  computed: {
    measurementRangeData() {
      return this.$store.getters.measurementRangeData;
    },
  },
  watch: {
    measurementRangeData(rangeData) {
      let data = [];
      for (let s in rangeData) {
        let datetime = new Date(rangeData[s].datetime);
        let temperature = rangeData[s].temperature;
        const humidity = rangeData[s].humidity;
        const lightIntensity = rangeData[s].lightintensity;
        data.push({
          date: datetime,
          temperature: temperature,
          humidity: humidity,
          lightIntensity: lightIntensity,
        });
      }
      this.chart.data = data;
    },
  },
  methods: {
    isActive(range) {
      return 'v-btn--active';
    },
    pollMeasurements() {
      const queryData = {
        startDate: this.sDate,
        endDate: this.eDate,
        epsilon: 0.7,
      };
      this.$store.dispatch('getMeasurementRange', queryData);
    },
    setRangeToday() {
      this.sDate = today;
      this.eDate = tomorrow;
      this.pollMeasurements();
    },
    setRangeYesterday() {
      this.sDate = yesterday;
      this.eDate = today;
      this.pollMeasurements();
    },
    setRangeWeek() {
      this.sDate = startWeek;
      this.eDate = tomorrow;
      this.pollMeasurements();
    },
    setRangeMonth() {
      this.sDate = startMonth;
      this.eDate = tomorrow;
      this.pollMeasurements();
    },
    setRangeYear() {
      this.sDate = startYear;
      this.eDate = tomorrow;
      this.pollMeasurements();
    },
    setRangeLastWeek() {
      this.sDate = lastWeekStart;
      this.eDate = startWeek;
      this.pollMeasurements();
    },
    setRangeLastMonth() {
      this.sDate = startLastMonth;
      this.eDate = startMonth;
      this.pollMeasurements();
    },
    setRangeLastYear() {
      this.sDate = startLastYear;
      this.eDate = startYear;
      this.pollMeasurements();
    },
    setRangeLast7Days() {
      this.sDate = sevenDaysAgo;
      this.eDate = tomorrow;
      this.pollMeasurements();
    },
    setRangeLast30Days() {
      this.sDate = thirtyDaysAgo;
      this.eDate = tomorrow;
      this.pollMeasurements();
    },
    setRangeLast90Days() {
      this.sDate = ninetyDaysAgo;
      this.eDate = tomorrow;
      this.pollMeasurements();
    },
  },
  mounted() {
    am4core.useTheme(am4themes_animated);
    const chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
    chart.numberFormatter.numberFormat = '0.0';
    // chart.paddingRight = 20;
    chart.data = [];
    // Increase contrast by taking evey second color
    chart.colors.step = 3;
    chart.responsive.enabled = true;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    //dateAxis.renderer.grid.template.location = 0;
    //dateAxis.renderer.minWidth = 15;
    dateAxis.baseInterval = {
      timeUnit: 'minute',
      count: 15,
    };
    dateAxis.title.text = 'Date';

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    //valueAxis.renderer.minWidth = 35;
    valueAxis.title.text = 'Temperature [°C]';
    // valueAxis.renderer.line.strokeOpacity = 1;
    // valueAxis.renderer.line.strokeWidth = 2;
    //valueAxis.renderer.line.stroke = am4core.color("#3787ac");

    // let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis2.tooltip.disabled = true;
    // //valueAxis2.renderer.minWidth = 35;
    // valueAxis2.renderer.opposite = true;
    // valueAxis2.title.text = 'Humidity [%]';

    // let valueAxis3 = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis3.tooltip.disabled = true;
    // //valueAxis3.renderer.minWidth = 35;
    // valueAxis3.renderer.opposite = true;
    // valueAxis3.title.text = 'Light Intensity [Lux]';
    // if (chart.yAxes.indexOf(valueAxis) != 0) {
    //   valueAxis.syncWithAxis = this.chart.yAxes.getIndex(0);
    // }

    let series1 = chart.series.push(new am4charts.LineSeries());
    series1.dataFields.dateX = 'date';
    series1.dataFields.valueY = 'temperature';
    series1.name = 'Temperature';
    series1.tooltipText = 'Temperature: {valueY.value} °C';
    series1.strokeWidth = 3;

    // let series2 = chart.series.push(new am4charts.LineSeries());
    // series2.dataFields.dateX = 'date';
    // series2.dataFields.valueY = 'humidity';
    // series2.name = 'Humidity';
    // series2.tooltipText = 'Humidity: {valueY.value}';
    // series2.yAxis = valueAxis2;
    // series2.strokeWidth = 3;

    // let series3 = chart.series.push(new am4charts.LineSeries());
    // series3.hidden = true; // Light Intensity is hidden by default!
    // series3.dataFields.dateX = 'date';
    // series3.dataFields.valueY = 'lightIntensity';
    // series3.name = 'Light Intensity';
    // series3.tooltipText = 'Light Intensity: {valueY.value}';
    // series3.yAxis = valueAxis3;
    // series3.strokeWidth = 3;

    valueAxis.renderer.labels.template.fill = series1.stroke;
    valueAxis.title.fill = series1.stroke;
    // valueAxis2.renderer.labels.template.fill = series2.stroke;
    // valueAxis2.title.fill = series2.stroke;
    // valueAxis3.renderer.labels.template.fill = series3.stroke;
    // valueAxis3.title.fill = series3.stroke;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;

    // let scrollbarX = new am4charts.XYChartScrollbar();
    // scrollbarX.series.push(series1);
    // scrollbarX.series.push(series2);
    // scrollbarX.series.push(series3);
    // chart.scrollbarX = scrollbarX;

    /* Add legend */
    chart.legend = new am4charts.Legend();

    dateAxis.renderer.grid.template.strokeOpacity = 0.07;
    valueAxis.renderer.grid.template.strokeOpacity = 0.07;
    // valueAxis2.renderer.grid.template.strokeOpacity = 0.07;

    this.chart = chart;

    this.pollMeasurements();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  width: 100%;
  height: 500px;
}
</style>
