<template>
  <div class="mt-16">
    <v-container>
      <v-row class="pa-3">
        <v-col>
          <v-card class="mx-auto" max-width="550">
            <v-card-text class="pa-3">
              <v-row no-gutters>
                <v-col cols="4">
                  <v-img
                    v-if="temperature <= 0.0"
                    src="@/assets/temperature-cold.png"
                    alt="Temperautre Image"
                    width="150"
                    height="150"
                  ></v-img>
                  <v-img
                    v-else-if="temperature <= 15.0"
                    src="@/assets/temperature-cool.png"
                    alt="Temperautre Image"
                    width="150"
                    height="150"
                  ></v-img>
                  <v-img
                    v-else-if="temperature <= 25.0"
                    src="@/assets/temperature-optimal.png"
                    alt="Temperautre Image"
                    width="150"
                    height="150"
                  ></v-img>
                  <v-img
                    v-else-if="temperature <= 30.0"
                    src="@/assets/temperature-warm.png"
                    alt="Temperautre Image"
                    width="150"
                    height="150"
                  ></v-img>
                  <v-img
                    v-else
                    src="@/assets/temperature-hot.png"
                    alt="Temperautre Image"
                    width="150"
                    height="150"
                  ></v-img>
                </v-col>
                <v-col align-self="center" class="text-h2 text-center" cols="8"
                  >{{ temperature | number('0.0') }} °C</v-col
                >
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="pa-3">
        <v-col>
          <v-card class="mx-auto" max-width="550">
            <v-card-text class="pa-3">
              <v-row no-gutters>
                <v-col align-self="center" cols="4">
                  <v-img
                    src="@/assets/humidity-high.png"
                    alt="Humidity Image"
                    width="150"
                    height="150"
                  ></v-img>
                </v-col>
                <v-col align-self="center" class="text-h2 text-center" cols="8"
                  >{{ humidity | number('0.0') }} %</v-col
                >
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="pa-3">
        <v-col>
          <v-card class="mx-auto" max-width="550">
            <v-card-text class="pa-3">
              <v-row no-gutters>
                <v-col cols="4">
                  <v-img
                    v-if="lightIntensity < 5"
                    src="@/assets/moon.png"
                    alt="Light Intensity Image"
                    width="150"
                    height="150"
                  ></v-img>
                  <v-img
                    v-else
                    src="@/assets/sun.png"
                    alt="Light Intensity Image"
                    width="150"
                    height="150"
                  ></v-img>
                </v-col>
                <v-col align-self="center" class="text-h2 text-center" cols="8"
                  >{{ lightIntensity | number('0.0') }} Lux</v-col
                >
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from 'vue';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
Vue.use(VueFilterDateFormat);

export default {
  name: 'Dashboard',
  intervalId: null,
  components: {},
  data: () => ({
    //
  }),
  computed: {
    datetime() {
      return new Date(this.$store.state.datetime);
    },
    temperature() {
      return this.$store.getters.temperature;
    },
    humidity() {
      return this.$store.getters.humidity;
    },
    lightIntensity() {
      return this.$store.getters.lightIntensity;
    },
  },
  methods: {
    pollMeasurements() {
      this.$store.dispatch('getMeasurements');
      this.intervalId = setInterval(() => {
        this.$store.dispatch('getMeasurements');
      }, 60 * 1000);
    },
  },
  created() {
    this.pollMeasurements();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
    this.intervalId = null;
  },
};
</script>
