import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Dashboard from '../views/Dashboard.vue'
import History from '../views/History.vue'
import Statistics from '../views/Statistics.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/hist',
    name: 'History',
    component: History
  },
  {
    path: '/stats',
    name: 'Statistics',
    component: Statistics
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  //console.log("Route Guard called!", to, from);
  if (!store.getters.isLoggedIn &&  to.path !== "/login") {
    next('/login');
  } else {
    next();
  }
})

export default router
